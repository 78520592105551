.spacer-top {
    padding-top: 140px;

    @include below($s) {
        padding-top: 100px;
    }
}

.job-content {
    h1 {
        margin-bottom: 60px;

        @include below($s) {
            margin-bottom: 20px;
        }
    }

    h2 {
        font-size: 16px;
        line-height: 19px;
        font-family: $apercumedium;
        color: $darkblue;
        text-transform: uppercase;
        margin-bottom: 20px;

        @include below($s) {
            margin-bottom: 5px;
        }
    }

    .button {
        min-width: 300px;
        margin-bottom: 50px;
        @include below($m) {
            display: none;
        }
    }

    p {
        font-size: 20px;
        line-height: 30px;
        font-family: $freightmedium;
        color: $black;
        margin-bottom: 44px;

        @include below($s) {
            font-size: 16px;
            line-height: 26px;
        }
    }

    @include below($l) {
        margin-left: 0;
    }
}

.job-sidebar {
    @include below($m) {
        margin-bottom: 160px;
    }

    @include below($s) {
        margin-bottom: 40px;
    }

    @include above($m) {
        margin-top: 11px;
    }

    .button {
        width: 100%;
        margin-bottom: 10px;
        &--pink {
            padding: 26px 0;
        }
        &--darkblue {
            padding: 24px 0;
        }
    }

    &__cto {
        margin-bottom: 21px;
    }

    &__information {
        margin-bottom: 26px;

        p {
            text-transform: uppercase;
            color: $black;
            font-size: 16px;
            line-height: 30px;
            font-family: $apercumedium;
            span {
                color: #999999;
            }
        }

        .logo {
            max-width: 300px;
            max-height: 300px;

            margin-bottom: 20px;
        }
    }

    &__tips {
        margin-bottom: 26px;

        &__title {
            font-size: 16px;
            line-height: 19px;
            color: $darkblue;
            font-family: $apercumedium;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        .input {
            margin-bottom: 10px;
            padding: 15px;
            font-size: 16px;
            line-height: 20px;
        }

        &__button {
            width: 100%;
        }
    }

    &__share {
        &__title {
            font-size: 16px;
            line-height: 19px;
            color: $black;
            font-family: $apercumedium;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        &__icon {
            width: 40px;
            height: 40px;
            float: left;
            background-size: 40px 40px;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 10px;

            &--twitter {
                background-image: url("../img/icon-twitter.svg");
            }

            &--fb {
                background-image: url("../img/icon-fb.svg");
            }

            &--ggl {
                background-image: url("../img/icon-googleplus.svg");
            }

            &--linkedin {
                background-image: url("../img/icon-linkedin.svg");
            }
        }
    }
}
