.search-button {
    width: 70px;
    height: 70px;
    background: $white;
    display: block;
    border-radius: 100%;
    position: relative;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.3);
    background-image: url(../img/icon-search.svg);
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 224px;
    z-index: 10;

    @include below($s) {
        width: 60px;
        height: 60px;
    }

    &.open {
        background-image: url(../img/icon-search-close.svg);
        + .search-label {
            display: none;
        }
    }
}

.search-label {
    background-color: $darkblue;
    display: inline-block;
    padding-top: 17px;
    padding-bottom: 19px;
    padding-left: 32px;
    padding-right: 32px;
    @include vertical-align(absolute);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
    opacity: 0;
    left: 0;
    right: auto;
    transition: all 0.3s ease-in-out;
    width: 207px;

    &--visible {
        opacity: 1;
    }

    &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 0 7px 7px;
        border-color: transparent transparent transparent $darkblue;
        @include vertical-align(absolute);
        right: -7px;
    }

    p {
        color: #fff;
        font-size: 12px;
        line-height: 14px;
        font-family: $apercumedium;
        text-transform: uppercase;
    }
}

.search-button-wrap {
    height: 70px;
    max-width: 300px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1001;

    @include below($s) {
        height: 60px;
        bottom: 10px;
        right: 10px;
    }
}

.bot-view-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $purple;
    z-index: 11;
    //display:block;
    display: none;
    pointer-events: none;

    &--visible {
        display: block;
    }
}

.expand-bot-wrap {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 70px;
    height: 70px;
    z-index: 1000;
}

.expand-bot-bg {
    @include align-both(absolute);
    width: 10px;
    height: 10px;
    background: $purple;
    transition-duration: 0.4s;
    border-radius: 100%;

    @media screen and (orientation: portrait) {
        &.expanded {
            height: 300vh;
            width: 300vh;
        }
    }

    @media screen and (orientation: landscape) {
        &.expanded {
            height: 300vw;
            width: 300vw;
        }
    }
}
