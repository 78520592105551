.job-contact {
    margin-bottom: 40px;

    @include below($l) {
        margin-left: 0;
    }

    &__title {
        font-size: 40px;
        line-height: 50px;
        color: $darkblue;
        font-family: $platformbold;
        margin-bottom: 30px;
    }

    &__content {
        margin-top: 29px;
    }

    &__text {
        font-size: 20px;
        line-height: 34px;
        font-family: $apercumedium;
        color: $darkblue;

        &--spacer {
            margin-bottom: 35px;
        }

        a {
            text-decoration: none;
            color: $purple;
            transition: color 0.2s ease-in-out;

            &:hover {
                color: $darkblue;
            }
        }
    }
}
