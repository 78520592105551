.footer {
	background-color: $darkblue;
	padding: 45px 0;
	position: relative;
	margin-top: 68px;
	@include below($l) {
		padding-bottom: 160px;
	}
	@include below($m) {
		padding-bottom: 150px;
	}
	@include below($s) {
		padding: 20px 0 150px;
		background-size: 120px 93px;
	}

	&--nmt {
		margin-top: 0;
	}

	&__section {
		list-style: none;

		&--rightaligned {
			@include below($s) {
				text-align: right;
			}
		}

		li {
			width: 100%;
			display: inline-block;
		}

        .menu-item-has-children .sub-menu {
            display: none;
        }

        .menu-item a {
            text-transform: uppercase;
        }

        .menu-item a,
		&__link {
			font-size: 16px;
			line-height: 34px;
			color: $white;
			font-family: $apercumedium;
			text-decoration: none;
            transition: opacity 0.2s ease-in-out;

			@include below($s) {
				font-size: 12px;
				line-height: 30px;
			}

			&--uppercase {
				text-transform: uppercase;
			}

			&--spacer {
				margin-bottom: 34px;
				display: block;
			}

			&:hover {
				opacity: 0.6;
			}
		}
	}

	&__badge {
		float: left;
		margin-right: 140px;

		a {
			display: block;
			@include below($l) {
				vertical-align: middle;
				display: inline-block;
			}
		}

		svg {
			margin-bottom: 20px;
			width: 80px;
			height: 91px;
			display: block;
			@include below($l) {
				margin-bottom: 0;
				float: left;
				display: inline-block;
			}
		}

		img {
			width: 80px;
			height: auto;
			@include below($l) {
				float: left;
				position: relative;
				top: -6px;
			}
		}
		@include below($l) {
			position: absolute;
			bottom: 10px;
			left: 15px;
			margin-right: 0;
		}
		span{
			display: block;
			@include below($l) {
				vertical-align: middle;
				display: inline-block;
			}
			img{
				padding-top:10px;
				width: 125px;
				@include above($l){
					padding-top: 20px;
					margin-left: -15px;
				}
			}
		}
	}

	&__anchor {
		position: absolute;
		top: 40px;
		right: 74px;
		color: $white;
		font-size: 16px;
		line-height: 34px;
		font-family: $apercumedium;
		text-decoration: none;
		transition: opacity 0.2s ease-in-out;
		@include below($l) {
			right: 30px;
		}
		@include below($s) {
			display: none;
		}

		&:after {
			content: '';
			width: 22px;
			height: 15px;
			background-image: url("../img/icon-arrow-up.svg");
			display: inline-block;
			background-repeat: no-repeat;
			margin-left: 8px;
		}

		&:hover {
			opacity: 0.6;
		}
	}
}