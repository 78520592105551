.social {
	width:40px;
	height:40px;
	display:block;
	transition:background .2s ease-in-out;
	float:left;
	margin-right:10px;

	&:hover {
		svg g{
			fill:#5433A1;
		}
	}
}