.coworkers {
    &__title {
        border-bottom: 2px solid $black;
        margin-bottom: 28px;

        &--border-white {
            border-bottom: 2px solid $white;
        }

        h2 {
            font-family: $apercumedium;
            color: $black;
            font-size: 16px;
            line-height: 26px;
            text-transform: uppercase;
            margin-bottom: 5px;

            &.white {
                color: $white;
            }
        }
    }

    &__col {
        margin-bottom: 59px;

        &__image {
            width: 100%;
            height: auto;
            margin-bottom: 10px;
        }

        &__name {
            font-family: $apercumedium;
            font-size: 16px;
            line-height: 24px;
            color: $black;
            &--white {
                color: $white;
            }
        }

        &__position {
            font-family: $apercumedium;
            font-size: 16px;
            line-height: 24px;
            color: $black;
            margin-bottom: 8px;
            &--white {
                color: $white;
            }
        }

        &__link {
            font-size: 16px;
            line-height: 24px;
            color: $purple;
            transition: color 0.2s ease-in-out;
            text-decoration: none;
            font-family: $apercumedium;
            display: block;

            &--white-opacity {
                color: rgba(255, 255, 255, 0.6);
                &:hover {
                    color: rgba(255, 255, 255, 1) !important;
                }
            }

            &:hover {
                color: $darkblue;
            }
        }
    }
}
