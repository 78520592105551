.button {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    line-height: 18px;
    font-family: $apercumedium;
    text-transform: uppercase;
    padding: 26px 0;
    float: left;
    text-align: center;
    transition: background 0.2s ease-in-out;
    border-radius: 0;
    outline: none;

    @include below($s) {
        font-size: 14px;
    }

    &--small {
        padding: 10px 0 !important;
    }

    &--pink {
        background: $pink;
        &:hover {
            background: #dc4b5b;
        }
    }

    &--white {
        background: $white;
        color: $purple;
        padding: 24px 112px;
        &:hover {
            background: $darkblue;
            color: $white;
        }
    }

    &--purple {
        background: $purple;
        border: 2px solid $white;
        padding: 24px 112px;
        &:hover {
            background: $white;
            color: $purple;
            border: 2px solid $white;
        }
    }

    &--bordered-purple {
        border: 2px solid $purple;
        color: $purple;
        padding: 24px 112px;
        &:hover {
            background: $purple;
            color: $white;
            border: 2px solid $white;
        }
    }

    &--darkblue {
        background: #f5f5f5;
        color: $darkblue;
        border: 2px solid $darkblue;
        padding: 24px 112px;
        &:hover {
            background: $darkblue;
            color: $white;
            border: 2px solid $darkblue;
        }
    }

    &--bordered-white {
        border: 2px solid $white;
        padding: 24px 0;
        width: 100%;

        &:hover {
            background: $white;
            color: $purple;
        }
    }

    &--dropdown {
        max-width: 410px;
        width: 100%;
        margin-bottom: 75px;
    }
}

.back-to-search {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    font-family: $apercumedium;
    background: $purple;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    padding: 20px 0;
    margin-bottom: 70px;

    position: fixed;
    left: 0;
    right: 0;
    top: 95px;
    transition: transform 0.2s ease-in-out;
    z-index: 2;

    @include below($s) {
        top: 60px;
        padding: 15px 0;
    }

    &--minimized {
        transform: translateY(-95px);
        @include below($s) {
            transform: translateY(-60px);
        }
    }

    img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
}
