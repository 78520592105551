// make sure that padding applied to our elements is
// added within their calculated dimensions, giving us far more freedom for defining column widths.
*,
*:after,
*:before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

// Wrapper rules to make sure our wrapper element bahaves itself.
.wrapper {
    width: 100%;
    max-width:$grid-max-width;
    margin: 0 auto;    
	
	@include below($grid-max-width){
    	padding:0 20px;
	}
	
	@include below($s){
		padding:0 15px 60px 15px;
	}

    &.nm {
    	margin:0;
    	max-width:100%;
    }

	&.np {
		padding:0 !important;
	}

	&.ng {
		gap:0 !important;
	}

    &.fixed {
    	margin:0;
	}
	
	&.with-submenu {
		@include below($s){
			margin-top:80px;
		}
	}

    &.row {
        display: flex;
        flex-wrap: wrap;
        gap: $gutter;
    }
}

// Wrapper rules to make sure our wrapper element bahaves itself.
.container {
    width: 100%;
    max-width:$grid-max-width;
    margin: 0 auto;
	padding:0;
	gap:0;

	&.ng {
		gap:0 !important;

		@include above($s){
			gap:20px !important;
		}
	}

    &.fixed {
    	margin:0;
	}
	
	&.with-submenu {
		@include below($s){
			margin-top:80px;
		}
	}

    &.row {
        display: flex;
        flex-wrap: wrap;
        gap: $gutter;
    }
}

.holder {
    width:100%;
    display:inline-block;
}

// Clearfix the wrapper with columns inside.
.wrapper:after,
.row:after {
	content: "";
	display: table;
	clear: both;
}

.reverse-order {
	@include below($s){
		display:flex;
		flex-direction:column-reverse;
	}
}

.offset-1 {
	margin-left:8.527131783%;
	@include below($m){
		margin-left:0;
	}
}

.offset-2 {
	margin-left:1.705426357%;
	@include below($m){
		margin-left:0;
	}
}

.offset-3 {
	margin-left:2.558139535%;
	@include below($m){
		margin-left:0;
	}
}

// Columns


// Grid settings.
@mixin grid($size, $gutter: $gutter) {
	$cycle: math.div(12, $size);
	$colSize: #{$size + '/12'};
	width: calc(100% *  #{$colSize} - (#{$gutter} - #{$gutter} * #{$colSize}));

 	&:nth-child(n) {
   		margin-right: $gutter;
  	}
  	&:last-child {
    	margin-right: 0;
  	}
}

// Custom settings.
.align-center{
	text-align: center;
}

// Utility classes remain the same
.col-right { margin-left: auto; }
.col-left { margin-right: auto; }
.col-center {
    margin-left: auto;
    margin-right: auto;
}
// Default columns (above $l breakpoint)
@include above($l) {
    .col-1 { width: calc(8.333% - ($gutter * 11/12)); }
    .col-2 { width: calc(16.666% - ($gutter * 10/12)); }
    .col-3 { width: calc(25% - ($gutter * 9/12)); }
    .col-4 { width: calc(33.333% - ($gutter * 8/12)); }
    .col-5 { width: calc(41.666% - ($gutter * 7/12)); }
    .col-6 { width: calc(50% - ($gutter * 6/12)); }
    .col-7 { width: calc(58.333% - ($gutter * 5/12)); }
    .col-8 { width: calc(66.666% - ($gutter * 4/12)); }
    .col-9 { width: calc(75% - ($gutter * 3/12)); }
    .col-10 { width: calc(83.333% - ($gutter * 2/12)); }
    .col-11 { width: calc(91.666% - ($gutter * 1/12)); }
    .col-12 { width: 100%; }
}

// Large breakpoint columns
@include below($l) {
    [class*="l-col-"] {
        &.no--gutter {
            gap: 0;
        }
    }
    .l-col-1 { width: calc(8.333% - ($gutter * 11/12)); }
	.l-col-2 { width: calc(16.666% - ($gutter * 10/12)); }
	.l-col-3 { width: calc(25% - ($gutter * 9/12)); }
	.l-col-4 { width: calc(33.333% - ($gutter * 8/12)); }
	.l-col-5 { width: calc(41.666% - ($gutter * 7/12)); }
	.l-col-6 { width: calc(50% - ($gutter * 6/12)); }
	.l-col-7 { width: calc(58.333% - ($gutter * 5/12)); }
	.l-col-8 { width: calc(66.666% - ($gutter * 4/12)); }
	.l-col-9 { width: calc(75% - ($gutter * 3/12)); }
	.l-col-10 { width: calc(83.333% - ($gutter * 2/12)); }
	.l-col-11 { width: calc(91.666% - ($gutter * 1/12)); }
	.l-col-12 { width: 100%; }
}

// Medium breakpoint columns
@include below($m) {
    [class*="m-col-"] {
        &.no--gutter {
            gap: 0;
        }
    }
    .m-col-1 { width: calc(8.333% - ($gutter * 11/12)); }
	.m-col-2 { width: calc(16.666% - ($gutter * 10/12)); }
	.m-col-3 { width: calc(25% - ($gutter * 9/12)); }
	.m-col-4 { width: calc(33.333% - ($gutter * 8/12)); }
	.m-col-5 { width: calc(41.666% - ($gutter * 7/12)); }
	.m-col-6 { width: calc(50% - ($gutter * 6/12)); }
	.m-col-7 { width: calc(58.333% - ($gutter * 5/12)); }
	.m-col-8 { width: calc(66.666% - ($gutter * 4/12)); }
	.m-col-9 { width: calc(75% - ($gutter * 3/12)); }
	.m-col-10 { width: calc(83.333% - ($gutter * 2/12)); }
	.m-col-11 { width: calc(91.666% - ($gutter * 1/12)); }
	.m-col-12 { width: 100%; }
}

// Small breakpoint columns
@include below($s) {
    // Reset default columns to full width
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6,
    .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        width: 100%;
    }

    [class*="s-col-"] {
        &.no--gutter {
            gap: 0;
        }
    }
    .s-col-1 { width: calc(8.333% - ($gutter * 11/12)); }
	.s-col-2 { width: calc(16.666% - ($gutter * 10/12)); }
	.s-col-3 { width: calc(25% - ($gutter * 9/12)); }
	.s-col-4 { width: calc(33.333% - ($gutter * 8/12)); }
	.s-col-5 { width: calc(41.666% - ($gutter * 7/12)); }
	.s-col-6 { width: calc(50% - ($gutter * 6/12)); }
	.s-col-7 { width: calc(58.333% - ($gutter * 5/12)); }
	.s-col-8 { width: calc(66.666% - ($gutter * 4/12)); }
	.s-col-9 { width: calc(75% - ($gutter * 3/12)); }
	.s-col-10 { width: calc(83.333% - ($gutter * 2/12)); }
	.s-col-11 { width: calc(91.666% - ($gutter * 1/12)); }
	.s-col-12 { width: 100%; }
}

// Base column styles
[class*="col-"] {
    float: none;
}