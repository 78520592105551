.arrowed-link {
	color:$darkblue;
	font-family:$apercumedium;
	font-size:16px;
	line-height:19px;
	text-decoration:none;
	text-transform:uppercase;
	position:relative;
	padding-right:32px;
	backface-visibility:hidden;

	@include below($s){
		font-size:12px;
		line-height:14px;
		padding-right:20px;
	}

	&--white {
		color:$white;
		&:after {
			background-image:url(../img/icon-link-arrow-white.svg);
		}
	}

	&--darkblue {
		&:after {
			background-image:url(../img/icon-link-arrow.svg);
		}
    }

    &--purple {
		&:after {
			background-image:url(../img/icon-link-arrow-purple.svg);
		}
	}

	&:after {
		content:'';
		width:22px;
		height:15px;
		background-size:cover;
		position:absolute;
		right:0;
		top:2px;
		transition:transform .2s ease-in-out;

		@include below($s){
			width:11px;
			height:8px;
			top:3px;
		}
	}

	&:hover {
		&:after {
			transform:translateX(10px);
		}
	}
}