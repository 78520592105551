@font-face {
	font-family: 'Conv_apercu_medium_pro';
	src: url('../fonts/apercu_medium_pro.eot');
	src: local('☺'), url('../fonts/apercu_medium_pro.woff') format('woff'), url('../fonts/apercu_medium_pro.ttf') format('truetype'), url('../fonts/apercu_medium_pro.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Platform-Bold';
	src: url('../fonts/Platform-Bold.eot');
	src: local('☺'), url('../fonts/Platform-Bold.woff') format('woff'), url('../fonts/Platform-Bold.ttf') format('truetype'), url('../fonts/Platform-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_apercu_bold_pro';
	src: url('../fonts/apercu_bold_pro.eot');
	src: local('☺'), url('../fonts/apercu_bold_pro.woff') format('woff'), url('../fonts/apercu_bold_pro.ttf') format('truetype'), url('../fonts/apercu_bold_pro.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

$platformbold: 'Conv_Platform-Bold';
$apercumedium: 'Conv_apercu_medium_pro';
$freightmedium: 'freight-text-pro',serif;
$apercubold: 'Conv_apercu_bold_pro';

.title-l {
	font-family:$platformbold;
	font-size:70px;
	line-height:80px;
	color:$darkblue;


	@media only screen and (max-width:768px){
		font-size:40px;
		line-height:46px;
	}

	&--white {
		color:$white;
	}
}

.title {
	font-family:$platformbold;
	font-size:50px;
	line-height:60px;

	@media only screen and (max-width: 1024px) {
		font-size: 40px;
		line-height: 46px;
	}

	@media only screen and (max-width: 768px) {
		font-size: 26px;
		line-height: 30px;
	}

	@media only screen and (max-width: 576px) {
		font-size: 20px;
		line-height: 24px;
	}

	&--white {
		color:$white;
	}

	&--darkblue {
		color:$darkblue;
    }

    &--purple {
		color:$purple !important;
	}
}

.title-ml {
	font-size: 32px;
	line-height: 40px;
	color:$black;
}

.title-m {
	font-size:24px;
	line-height:33px;
	color:$darkblue;
}

.title-s {
	font-family:$apercumedium;
	font-size:16px;
	line-height:26px;
	text-transform:uppercase;
	color:$darkblue;
}

.section-title {
	font-size:28px;
	line-height:34px;
	color:$darkblue;
	font-family:$apercumedium;
	margin-bottom:52px;

	@media only screen and (max-width:600px){
		font-size:20px;
		line-height:24px;
		margin-bottom:20px;
	}
}

.preamble {
	font-family:$apercumedium !important;
	font-size:24px !important;
	line-height:34px !important;
    margin-bottom:38px !important;

	@media only screen and (max-width:600px){
		font-size:16px !important;
		line-height:22px !important;
	}

	&--darkblue {
		color:$darkblue !important;
	}

	&--white {
		color:$white !important;
	}
}

.copy {
    font-family: $apercumedium;

    &--purple {
		color:$purple !important;
	}
}