body.job-ad-iframe {
	padding: 0;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	background: transparent;

	table {
		width: 100%;
		max-width: 100%;
		margin-bottom: 20px;
	}

	th, td {
		text-align: left;
		padding: 5px 3px;
		font-size: 16px;
	}

	td {
		color: #666666;
	}

	a {
		color: #666666;

		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}