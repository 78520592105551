.fact-box {
    margin-bottom: 52px;
    display: inline-block;
    width: 100%;
    display: flex;
    align-items: center;

    @include below($m) {
        display: block;
    }

    @include below($s) {
        margin-bottom: 30px;
    }

    &__image {
        width: 50%;

        @include below($m) {
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content {
        width: 50%;
        position: relative;
        text-align: left;

        @include below($m) {
            width: 100%;
        }

        &__text-ratio {
            position: relative;

            &__inner {
                padding: percentage(math.div(50, 645));

                @include below($m) {
                    position: static;
                }

                p {
                    font-size: 20px !important;
                    line-height: 32px !important;

                    @include below($l) {
                        font-size: 18px !important;
                        line-height: 28px !important;
                    }

                    @include below($s) {
                        font-size: 14px !important;
                        line-height: 20px !important;
                    }
                }
            }
        }

        .title {
            font-size: 40px !important;
            line-height: 50px !important;
            margin-bottom: 20px;
            @include below($l) {
                margin-bottom: 20px;
            }

            @include below($s) {
                font-size: 24px !important;
                line-height: 30px !important;
            }
        }

        &--darkblue {
            background: $darkblue;
        }

        &--green {
            background: $green;
        }

        &--blue {
            background: $orange;
        }

        &--purple {
            background: $purple;
        }

        .preamble:last-child {
            margin-bottom: 0 !important;
        }
    }
}
