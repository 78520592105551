.contact-row {
	margin-bottom:85px;

	&--lm {
		margin-bottom:70px;
	}

	div:last-child {
		margin-right:0;
	}

	&__column {
		span {
			width:100%;
			display:block;
			text-transform:uppercase;
			font-size:16px;
			line-height:28px;
			color:$darkblue;
			font-family:$apercumedium;

			&.white {
				color:$white;
			}
		}

		a {
			font-size:20px;
			line-height:28px;
			font-family:$apercumedium;
			color: $purple;
			text-decoration:none;
			transition:color .2s ease-in-out;

			&.white-opacity {
				color:rgba(255, 255, 255, .6);
				&:hover {
					color:rgba(255, 255, 255, 1);
				}
			}

			&:hover {
				color:$black;
			}
		}
	}
}