.coworkers-contact {
    text-align: center;
    margin: 50px 0;

    .coworker {
        display: inline-block;
        margin: 20px;

        img {
            border-radius: 100%;
            width: 130px;
            height: 130px;
            margin-bottom: 15px;
        }

        a {
            display: block;
            margin-top: 10px;
            color: $black !important;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}