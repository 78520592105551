.vision {
    margin-bottom: 52px;
}

.vision-slider-outer {
    position: relative;
}

.vision-slider {
    position: relative;
    height: 560px;

    @include below(900px) {
        height: 617px;
    }

    @include below(690px) {
        height: 667px;
    }

    @include below(320px) {
        height: 547px;
    }

    &__slide {
        height: 560px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;

        @include below(900px) {
            overflow: visible;
        }

        @include below($s) {
            height: 568px;
        }

        @include below(320px) {
            height: 547px;
        }

        &--active {
            .vision-slider__slide__content {
                opacity: 1;
            }

            .vision-slider__slide__image {
                opacity: 1;
                img {
                    transform: translate3d(0, 0, 0) scale(1.01);
                }
            }
        }

        &__image {
            width: 54.65116279%;
            float: left;
            position: relative;
            opacity: 0;
            overflow: hidden;

            @include below(900px) {
                width: 90%;
            }

            img {
                width: 100%;
                height: auto;
                transform: translate3d(0, 0, 0) scale(1.1);
                transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
        }

        &__content {
            width: 50%;
            height: 500px;
            float: right;
            position: relative;
            margin-top: -34%;
            opacity: 0;
            z-index: 9;
            transition: background 0.2s ease-in-out;
            padding: 50px;

            @include below(900px) {
                width: 80%;
                height: 420px;
            }

            @include below(740px) {
            }

            @include below(690px) {
                width: 95%;
                margin-top: -20%;
                padding: 20px;
            }

            @include below(320px) {
                width: 100%;
                height: 460px;
            }

            .title {
                margin-bottom: 27px;

                @include below($m) {
                    font-size: 26px;
                    margin-bottom: 10px;
                }
            }

            p {
                font-size: 20px;
                line-height: 32px;
                color: #fff;
                font-family: $apercumedium;

                @include below(1260px) {
                    font-size: 18px;
                    line-height: 28px;
                }
                @include below($m) {
                    font-size: 17px;
                    line-height: 20px;
                }
            }

            &--darkblue {
                background: $darkblue;
            }

            &--blue {
                background: $orange;
                .title {
                    color: #1f2a49;
                }

                p {
                    color: #1f2a49;
                }
            }

            &--green {
                background: $green;

                .title {
                    color: #1f2a49;
                }

                p {
                    color: #1f2a49;
                }
            }
        }
    }

    &__controls {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        z-index: 10;
        padding: 0 50px;
        height: 15px;

        @include below(690px) {
            padding: 0 20px;
        }

        &--blue {
            .vision-slider__controls__arrow-controls__control--prev {
                background-image: url(../img/icon-chevron-left-darkblue.svg);
            }

            .vision-slider__controls__arrow-controls__control--next {
                background-image: url(../img/icon-chevron-right-darkblue.svg);
            }

            .vision-slider__controls__pagination li .vision-slider__control {
                border: 2px solid $darkblue;
            }

            .vision-slider__controls__pagination
                li
                .vision-slider__control--active {
                border: 2px solid $darkblue;
                background: $darkblue;
            }
        }

        &--green {
            .vision-slider__controls__arrow-controls__control--prev {
                background-image: url(../img/icon-chevron-left-darkblue.svg);
            }

            .vision-slider__controls__arrow-controls__control--next {
                background-image: url(../img/icon-chevron-right-darkblue.svg);
            }

            .vision-slider__controls__pagination li .vision-slider__control {
                border: 2px solid $darkblue;
            }

            .vision-slider__controls__pagination
                li
                .vision-slider__control--active {
                border: 2px solid $darkblue;
                background: $darkblue;
            }
        }

        &__arrow-controls {
            float: left;

            &__control {
                width: 9px;
                height: 15px;
                background-size: cover;
                background-position: center center;
                display: inline-block;
                &--next {
                    background-image: url(../img/icon-chevron-right-white.svg);
                }
                &--prev {
                    background-image: url(../img/icon-chevron-left-white.svg);
                    margin-right: 40px;
                }
            }
        }

        &__pagination {
            list-style: none;
            float: right;

            @include below($s) {
                display: none;
            }

            li {
                float: left;

                .vision-slider__control {
                    width: 14px;
                    height: 14px;
                    border-radius: 100%;
                    border: 2px solid $white;
                    float: right;
                    transition: background 0.2s ease-in-out;
                    margin-left: 20px;

                    &--active {
                        background: $white;
                    }

                    &:hover {
                        background: $white;
                    }
                }
            }
        }
    }
}
