/*
	shared
*/
.formelements_hide {
	position: relative;
	visibility: hidden;
	height: 0;
	width: 0;
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
}

select.formelements_hide {
	position: absolute;
}

.formelements_disabled, .formelelemts_item_disabled {
	opacity: 0.4;
	filter: alpha(opacity=50);
}

.formelements_radio, .formelements_check, .formelements_select {
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/*
	checkbox and radiobutton
*/
/*
	select box
*/
.formelements_select .formelements_select_display {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* tinyscrollbar css */
.formelements_listwrap .viewport {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
}

.formelements_listwrap .overview {
	list-style: none;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.formelements_listwrap .scrollbar .thumb, .formelements_listwrap .scrollbar .thumb .end {
	background-color: #ddd;
	border-radius: 2px;
}

.formelements_listwrap .scrollbar, .formelements_listwrap .track, .formelements_listwrap .scrollbar .thumb, .formelements_listwrap .scrollbar .thumb .end {
	width: 4px;
	box-sizing: content-box;
}

.formelements_listwrap .scrollbar {
	position: absolute;
	height: 100%;
	right: 0px;
	outline: 1px solid #eee;
}

.formelements_listwrap .scrollbar {
	background-color: #fff;
	padding: 0px 2px;
	z-index: 1000;
}

.formelements_listwrap .track {
	height: 100%;
	padding: 0;
}

.formelements_listwrap .scrollbar .thumb {
	height: 20px;
	cursor: pointer;
	overflow: hidden;
	position: absolute;
	top: 0;
}

.formelements_listwrap .scrollbar .thumb .end {
	overflow: hidden;
	height: 5px;
}

.formelements_select .formelements_clear {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	height: 20px;
	width: 20px;
	line-height: 20px;
	text-align: center;
}

.formelements_select.formelements_empty .formelements_clear {
	display: none;
}

.formelements_listwrap .disable {
	display: none;
}


/* Form elements JS */

/*
    select box
*/

.formelements_select {
    position: relative;
    display: inline-block;
    width:100%;
    font-size:16px;
    line-height:18px;
	font-family:$apercumedium;
	
	@include below($l){
		max-width:100%;
	}
}

.formelements_select .formelements_select_display {
    height: 100%;
	padding: 24px 24px;
    font-size:16px;
    line-height: 20px;
    cursor: pointer;
    text-align:center;
    text-transform:uppercase;
	padding:22px 0;
	
	@include below($s){
		font-size:12px;
		line-height:14px;
		padding:15px 0;
	}
}

.formelements_select .formelements_listwrap {
    position: absolute;
    top:100%;
	left:-2px;
	right:-2px;
    background-color: #fff;
    display: none;
    z-index: 1000;
    padding-top:30px;
    padding-bottom:30px;
    padding-left:30px;
}

.formelements_select.formelements_tinyscrollbar .formelements_listwrap {
    //height: 309px;
    //overflow: hidden;
}

.formelements_select .formelements_searchwrap {
    position: relative;
    border-bottom: 1px solid #eee;
    z-index: 2;
}

.formelements_select .formelements_searchwrap input {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    background: none;
    position: relative;
    line-height: 20px;
    padding:5px;
}

.formelements_select .formelements_select_contentwrap {
    position: relative;
    z-index: 1;
    height: 100%;
    padding-top: 30px;
    margin-top: -30px;
}

.formelements_select ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li:last-child {
    	margin-bottom:0;
    }
}
.formelements_select ul li {
    position: relative;
    cursor: pointer;
    z-index: 1;
    color:#BCBFC8;
    transition:color .2s ease-in-out;
    font-size:20px;
    line-height:24px;
    margin-bottom:21px;
	font-family:$apercumedium;
	
	@include below($s){
		font-size:12px;
		line-height:14px;
	}
}
.formelements_select ul li:hover {
    color:$darkblue;
}
.formelements_select ul li.active {
    color:$darkblue;
}
.formelements_select ul li.active:hover {}

.formelements_select ul li.placeholder {
	display:none;
}
.formelements_select ul li.search_hidden {
    display: none;
}

.formelements_select ul li[disabled='disabled'] {}
.formelements_select ul li[disabled='disabled']:hover {}