.job-listing {
	margin-bottom:60px;

	@include below($l){
		margin-left:0;
	}

	&__header {
		font-size:16px;
		line-height:19px;
		color:$darkblue;
		text-transform:uppercase;
		font-family:$apercumedium;
		margin-bottom:5px;
		cursor: pointer;
		padding-top: 20px;
		padding-bottom: 20px;
		border-bottom:2px solid $darkblue;

		span{
			float: right;
		}

		&:hover{
			color: $purple;

			span{
				text-decoration: underline;
			}
		}
	}

	&__list {
		list-style:none;
		width:100%;
		display: none;


		&.is-active{
			display: block;
		}
	}

	&__item {
		position:relative;
		border-bottom:2px solid $darkblue;

		&:last-child {
			border-bottom:2px solid $darkblue;
		}

		&:hover {
			.job-listing__item__link {
				&:after {
					transform:translateX(10px);
				}
			}

			.job-listing__item__title {
				text-decoration:underline;
			}
		}

		&__wraplink {
			display:block;
			text-decoration:none;
			padding: 20px 95px 20px 0;
			@include below($s){
				padding:20px 0;
			}
		}

		&__title {
			font-size:20px;
			line-height:24px;
			color:$darkblue;
			font-family:$apercumedium;
			margin-bottom:10px;
		}

		&__placedate {
			font-size:16px;
			line-height:19px;
			color:#A0A4B0;
			font-family:$apercumedium;
			display:block;
		}

		&__link {
			@include vertical-align(absolute);
			right:0;

			text-decoration:none;
			font-size:16px;
			line-height:19px;
			color:$darkblue;
			font-family:$apercubold;
			text-transform:uppercase;

			@include below($s){
				position:static;
				display:inline-block;
				transform:none;
				position:relative;
				margin-bottom:20px;
			}
		}
	}
}