.services {
    margin-bottom: 52px;

    .section-title {
        margin-bottom: 20px;
    }

    .flex-holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: -15px;
        margin-right: -15px;

        &.justify-start {
            justify-content: flex-start;
        }

        @include below($l) {
            justify-content: flex-start;
        }
    }

    .service-box {
        flex: 1 1 350px;
        max-width: calc( (100%/3) - 30px);
        text-decoration: none;
        background-color: #fff;
        padding: 30px 20px 40px;
        margin: 15px;
        box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.05);
        position: relative;

        .title {
            margin-bottom: 15px;
        }

        p {
            color: $black;
        }

        .arrowed-link {
            width: 42px;
            height: 28px;
            position: absolute;
            bottom: 30px;
            left: 20px;
            padding: 0;

            &:after {
                width: 100%;
                height: 100%;
                top: 0;
            }

        }

        &:hover {
            .arrowed-link:after {
                transform:translateX(10px);
            }
        }

        @include below($l) {
            max-width: calc( 50% - 30px);
        }

        @include below($m) {
            padding: 30px 20px;
            max-width: initial;

            .arrowed-link {
                width:22px;
                height:15px;
            }

            &:after {
                width:22px;
                height:15px;
            }
        }
    }

    .flex-holder.half-sized-boxes .service-box {
        flex: 1 0 40%;
    }
}