@use "sass:math";
//Settings – used with preprocessors and contain font, colors definitions, etc.
@import 'settings/colors.scss';
@import 'settings/fonts.scss';
@import 'settings/entypo.scss';
@import 'settings/grid-settings.scss';

//Tools – globally used mixins and functions. It’s important not to output any CSS in the first 2 layers.
@import 'tools/mixins.scss';
@import 'tools/breaker.scss';

//Generic – reset and/or normalize styles, box-sizing definition, etc. This is the first layer which generates actual CSS.
@import 'generic/normalize.scss';

//Elements – styling for bare HTML elements (like H1, A, etc.). These come with default styling from the browser so we can redefine them here.
@import 'elements/body.scss';
@import 'elements/html.scss';

//Objects – class-based selectors which define undecorated design patterns, for example media object known from OOCSS
@import 'objects/grid.scss';

//Components – specific UI components. This is where majority of our work takes place and our UI components are often composed of Objects and Components
@import 'components/sitemenu.scss';
@import 'components/button.scss';
@import 'components/step-navigation.scss';
@import 'components/arrowed-link.scss';
@import 'components/search-button.scss';
@import 'components/social.scss';
@import 'components/find-job.scss';
@import 'components/teaser.scss';
@import 'components/article-slider.scss';
@import 'components/swiper.scss';
@import 'components/page-intro.scss';
@import 'components/vision-slider.scss';
@import 'components/footer.scss';
@import 'components/article-content.scss';
@import 'components/article-contact.scss';
@import 'components/text-intro.scss';
@import 'components/contact-map.scss';
@import 'components/contact-module.scss';
@import 'components/contact-row.scss';
@import 'components/coworkers.scss';
@import 'components/area-select.scss';
@import 'components/form-elements.scss';
@import 'components/bot-view.scss';
@import 'components/formelements.scss';
@import 'components/job-content.scss';
@import 'components/job-listing.scss';
@import 'components/job-contact.scss';
@import 'components/job-apply.scss';
@import 'components/approveTermsCheckbox.scss';
@import 'components/flexibleContactForm.scss';
@import 'components/fact-box.scss';
@import 'components/coworkers-contact.scss';
@import 'components/page-links.scss';
@import 'components/services.scss';
@import 'job-ad-iframe';


.text--purple {
	color: $purple;
}

.text--black {
	color: $black;
}

//Trumps – utilities and helper classes with ability to override anything which goes before in the triangle, eg. hide helper class
//@import 'trumps';
