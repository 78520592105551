.page-intro {
    width: 100%;
    display: flex;
    margin: 0 auto;
    margin-bottom: 52px;
    overflow: hidden;

    @include below($m) {
        display: block;
    }

    &__image {
        width: 71.875%;
        float: left;
        position: relative;
        height: 41vw;
        background-size: cover;
        background-position: center center;

        @include below($m) {
            width: 100%;
            height: 62vw;
        }

        @include below($s) {
            padding: 0;
            height: 400px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(
                180deg,
                rgba(32, 42, 73, 0) 0%,
                rgba(32, 42, 73, 0.5) 100%
            );
        }

        &__overtitle {
            color: $white;
            font-size: 28px;
            line-height: 38px;
            font-family: $apercumedium;
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
        }

        &--fullwidth {
            width: 100%;

            /*
			Disable cubes for now.
			&:after {
				content:'';
				width:295px;
				height:240px;
				position:absolute;
				bottom:0;
				right:0;
				background-image:url(../img/cubes.svg);
				background-size:295px 240px;

				@include below($m){
					display:none;
				}
			}
			*/
        }

        /* 		&--article {
			&:after {
				width:240px;
				height:220px;
				background-image:url(../img/cubes-article.svg);
				background-size:240px 220px;
			}
		} */

        &__content {
            position: absolute;
            width: 100%;
            max-width: 1290px;
            left: calc((100% - 1290px) / 2);
            bottom: 60px;

            @include below($grid-max-width) {
                max-width: calc(100% - 40px);
                left: 20px;
            }

            @include below($s) {
                max-width: calc(100% - 30px);
                left: 15px;
            }

            .inner {
                width: 80%;
                max-width: 645px;

                @include below($s) {
                    width: 100%;
                }
            }

            .title-l {
                margin-bottom: 19px;
                text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

                @include below($s) {
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    hyphens: auto;
                }
            }

            p {
                font-size: 28px;
                line-height: 40px;
                color: $white;
                font-family: $apercumedium;
                max-width: 700px;
                width: 100%;

                @include below($m) {
                    font-size: 18px;
                    line-height: 28px;
                }

                @include below($s) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    &__area-select {
        float: left;
        width: 28.125%;
        flex-direction: column;

        @include below($m) {
            width: 100%;
        }

        &__area {
            height: 50%;
            position: relative;

            @include below($m) {
                float: left;
                width: 50%;
            }

            @include below($s) {
                width: 50%;
            }

            @include below(320px) {
                width: 100%;
            }

            a {
                padding: 10%;
                display: block;
                text-decoration: none;
                height: 100%;

                @include below($s) {
                    padding: 15px;
                }

                &:hover {
                    .arrowed-link {
                        &:after {
                            transform: translateX(10px);
                        }
                    }

                    .title {
                        transform: scale(1.05);
                    }
                }
            }

            .title {
                transition: transform 0.3s ease-in-out;
                transform-origin: top left;
                @include below($m) {
                    margin-bottom: 30px;
                }
            }

            .arrowed-link {
                position: absolute;
                bottom: 40px;

                @include below($m) {
                    position: relative;
                    bottom: auto;
                }
            }

            &--purple {
                background: $purple;
            }

            &--blue {
                background: $orange;
            }
        }
    }
}
