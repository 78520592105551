.article-content {
    width: auto;
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;

    &--mb {
        margin-bottom: 117px;
    }

    &--my {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    p {
        font-size: 20px;
        line-height: 30px;
        font-family: $freightmedium;
        margin: 30px 0;
        color: $darkblue;
        @include below($s) {
            font-size: 16px;
            line-height: 26px;
            margin: 10px 0;
        }
    }

    h2 {
        color: $darkblue;
        font-family: $apercumedium;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    img {
        width: 100%;
        height: auto;
    }

    a {
        color: $purple;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        padding-left: 22px;

        li {
            font-size: 20px;
            line-height: 30px;
            font-family: $freightmedium;
            color: $darkblue;
            @include below($s) {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}
