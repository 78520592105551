.article-contact {
	position:relative;
	margin-bottom:52px;

	&__content {
		.title {
			margin-bottom:35px;

			@include below($s){
				margin-bottom:20px;
			}
		}

		.preamble {
			@include below($s){
				display:none;	
			}
		}
	}

	&__image {
		img {
			width:100%;
			height:auto;
		}
	}

	&__bottom {
		position:absolute;
		bottom:0;
		left:0;
		right:0;

		@include below($s){
			position:static;
			display:inline-block;
			width:100%;
			margin-bottom:20px;
		}
		

		&__col {
			span {
				width:100%;
				display:block;
				text-transform:uppercase;
				font-size:16px;
				line-height:28px;
				color:$darkblue;
				font-family:$apercumedium;
			}

			a {
				font-size:20px;
				line-height:28px;
				font-family:$apercumedium;
				color:$pink;
				text-decoration:none;
				transition:color .2s ease-in-out;

				&:hover {
					color:$black;
				}
			}
		}
	}
}