.contact-module {
	max-width: 800px;
	display: block;
	margin: 0 auto 90px;
	text-align: center;

	.button{
		float: none;
	}
}
