
.job-apply{
	width:100%;
	margin:0 auto;
	display:block;
	background:$purple;


	&__toggle-wrap {
		width:100%;
		display:block;
	}

	&__toggle-button {
		width:33.3333333333%;
		display:inline-block;
		float:left;
		text-align:center;
		font-size:16px;
		line-height:19px;
		color: #fff;
		font-family:$apercubold;
		text-decoration:none;
		background-color:$pink;
		text-transform:uppercase;
		padding:25px 0;
		position:relative;
		transition:background-color .2s ease-in-out;

    .hide-interest & {
      width: 50%;

      &#interest-tab {
        display: none;
      }
    }

		@include below($s){
			font-size:12px;
		}

		&--active {
			background-color: $purple;
			color:$white;
			box-shadow: inset 0px -1px 1px rgba(0,0,0,0.15);

			&:before {
				content:'';
				width:10px;
				height:10px;
				background-color: $purple;
				position:absolute;
				left:50%;
				margin-left:-5px;
				transform:rotate(45deg);
				bottom:-5px;
				box-shadow: -1px -1px 1px rgba(0,0,0,0.15);
			}
		}

		&:hover {
			cursor:pointer;
			background-color:darken($purple, 5%);
			color:$white;
		}
	}

	.bot-title {
		margin-top:-10px;
	}

	&__view {
		max-width:1290px;
		width:100%;
		margin:0 auto;
		padding-top:140px;
		min-height:730px;
		position:relative;

		&.loading{

			&:before{
				will-change: border;
				content: '';
				display: block;
				width: 150px;
				height: 150px;
				border-radius: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background-color: $pink;
				z-index: 1;
				transition: .5s;
    			animation: loadingAnimation 2s infinite;
			}

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: $purple;
				opacity: .6;
			}
		}

		@include below($l){
			padding-top:96px;
		}

		@include below($s){
			display:inline-block;
			width:100%;
			padding-bottom:96px;
			padding-top:40px;
			min-height:500px;
		}

		&--npb {
			padding-bottom:0;
		}

		&--visible {
			opacity:1;
			pointer-events:auto;
			display:inline-block;
		}

        &-container {
            display: none;

            &-active {
                display:block;
            }
        }


		&__person {
			margin-bottom:39px;

			&__content {
				min-height:120px;
				@include below($s){
					min-height:auto;
				}
			}

			&__image {
				@include below($s){
					margin-bottom:20px;
				}
				img {
					width:100%;
					height:auto;
				}
			}

			.title {
				@include below($m){
					br {
						display:none;
					}
				}
			}
		}

		&__next {
			position:absolute;
			bottom:60px;
			text-align:right;

			&__button {
				font-size:16px;
				line-height:19px;
				font-family:$apercumedium;
				color:$white;
				text-decoration:none;
				text-transform:uppercase;
				&:hover {
					text-decoration:underline;
				}
			}
		}

		&__content {

			@include below($l){
				margin-left:0;
			}

			&__input-row {
				margin-bottom:30px;

				@include below($l){
					margin-left:0;
				}
				@include below($s){
					margin-bottom:0;
				}

				.input--mb {
					margin-bottom:10px;
				}
			}

			&__text {
				font-size:24px;
				line-height:34px;
				color:$white;
				font-family:$apercumedium;
				margin-bottom:32px;
				&--nmb {
					margin-bottom:0;
				}
			}

			&__small-text {
				font-family:$apercumedium;
				font-size:16px;
				line-height:24px;
				color:$white;

				@include below($s){
					font-size: 12px;
					line-height: 14px;
				}

				&--right {
					text-align:right;
					width:100%;
					display:block;
				}

				&--db {
					display:block;
				}

				&--mb {
					margin-bottom:24px;
				}

				&--file {
					display: none;
					margin-bottom:24px;
				}
			}

			&__section {
				margin-bottom:80px;
			}

			&__summary-section {
				margin-bottom:50px;
				position:relative;
				padding-right:225px;

				&--npr {
					padding-right:0;
				}

				&--mt {
					margin-top:30px;
				}

				&--dmb {
					margin-bottom:100px;
				}

				@include below($l){
					margin-left:0;
				}

				.button {
					float:right;
					border:none;
					max-width:300px;
					width:100%;
				}
			}

			&__summary-title {
				font-size:16px;
				line-height:19px;
				color:rgba(255, 255, 255, .60);
				font-family:$apercumedium;
				margin-bottom:3px;
				display:block;
			}

			&__content-text {
				font-size:16px;
				line-height:26px;
				color:$white;
				font-family:$apercumedium;
				&--duration {
					margin-bottom:20px;
				}
			}


			&__edit-button {
				font-size:16px;
				line-height:19px;
				color:$white;
				text-decoration:none;
				font-family:$apercumedium;
				top:0;
				right:0;
				position:absolute;
				&:hover {
					text-decoration:underline;
				}
			}


			.button {
				margin-bottom:10px;
			}

			.input {
				&--mb {
					margin-bottom:10px;
				}

				&--mt {
					margin-top:29px;
				}
			}
		}
	}

}


.wizard {
	position:relative;
	display:block;
	width:100%;

	.steps {
		display:none;
	}

	.content {
		width:100%;
		section .bot-title .text{
			display:none;
		}
	}

	.actions {
		position:absolute;
		right:0;
		left:0;
		margin-left: 8.527131783%;
		margin-right: 25.527132%;
		bottom:60px;

		@include below($l){
			width: calc(100% * 10/12 - (30px - 30px * 10/12));
		}

		@include below($m){
			width:100%;
			margin:0;
		}

		@include below($s){
			margin-left:0;
			margin-right:0;
		}

		ul {
			list-style:none;
		}

		li {
			float:right;

			&:first-child {
				float:left;

				a {
					padding-left:32px;
					padding-right:0;
					&:before {
						content:'';
						width:22px;
						height:15px;
						background-size:cover;
						position:absolute;
						left:0;
						top:2px;
						transition:transform .2s ease-in-out;
						background-image:url('../img/icon-link-arrow-white.svg');
						transform:rotate(180deg);
					}

					&:after {
						display:none;
					}
				}
			}
		}

		.disabled {
			a {
				display:none;
			}
		}

		a {
			font-size:16px;
			line-height:19px;
			font-family:$apercumedium;
			color:$white;
			text-decoration:none;
			text-transform:uppercase;
			position: relative;
			padding-right: 32px;
			backface-visibility: hidden;

			&:hover {
				text-decoration:underline;
			}

			&:after {
				content:'';
				width:22px;
				height:15px;
				background-size:cover;
				position:absolute;
				right:0;
				top:2px;
				transition:transform .2s ease-in-out;
				background-image:url('../img/icon-link-arrow-white.svg');
			}
		}
	}
}

p.error{
	color: $pink;
	margin: math.div($gutter, 3) 0;
	font-weight: bold;
}

.input-row {
	@include below($s){
		margin-bottom:20px;
	}
}

@keyframes loadingAnimation {
    0% { border: 20px solid $white; }
    50% { border: 5px solid $white;}
    100% { border: 20px solid $white;}
}