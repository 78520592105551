.page-links {
	margin-bottom:52px;
	display:inline-block;
    width:percentage(math.div(1070, 1290));
    margin: 60px auto;
    display: flex;
    align-items: center;

    @include below($m) {
        display: block;
        width: 100%;
    }

	@include below($s){
        margin-bottom:30px;
    }

	&__image {
		width:50%;

		@include below($m){
			width:100%;
		}

		img {
			width:100%;
			height:auto;
		}
	}

	&__content {
		width:50%;
        position:relative;
        text-align: left;
        margin-right: 30px;

        .text-right & {
            order: 2;
            margin-right: 0;
            margin-left: 30px;
        }

		@include below($m){
            width:100%;
            margin-right: 0;

            .text-right & {
                order: 1;
                margin-left: 0;
            }
		}

		&__text-ratio {
			position:relative;

			&__inner {

				@include below($m){
                    position:static;
                    margin-bottom: 30px;
				}

				p {
					font-size:16px !important;
                    line-height:24px !important;
                    margin-bottom: 30px;
				}
			}
		}

		.title {
            margin-bottom:20px;
            color: #2B2825;
		}

        .button {
            width: 100%;

            @include below($m){
                margin-bottom: 30px;
            }
        }
	}
}