.overflow-hidden {
    overflow: hidden;
}

.bot-view {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    height: 100vh;
    pointer-events: none;
}

.bot-view-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    overflow-y: auto;
    height: 100vh;
    -webkit-overflow-scrolling: touch;

    &:before {
        content: "";
        display: block;
        height: 165px;
        width: 100%;

        @include below($l) {
            height: 96px;
        }

        @include below($s) {
            height: 40px;
        }
    }

    &--visible {
        opacity: 1;
        pointer-events: auto;
    }
}

.bot-view-person {

    &__portrait {
        @include below($m) {
            margin-bottom: 10px;
        }

        &__image {
            width: 100%;
            height: auto;
        }
    }

    &__content {
        position: relative;
        margin-top: -10px;
        min-height: 120px;

        @include below($m) {
            margin-top: 0;
            min-height: auto;
        }

        .title {
            @include below($l) {
                min-height: auto !important;
            }
            @include below($s) {
                br {
                    display: none;
                }
            }
            span {
                color: $orange;
            }
        }
    }
}

.bot-view-options {
    @include below($l) {
        margin-left: 0;
    }

    .bot-view-show-all {
        display: inline-block;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 30px;
        border-bottom: 2px solid #fff;
        font-family: $apercumedium;
        text-decoration: none;
        padding-bottom: 2px;
    }

    &--mb {

        @include below($l) {
            margin-bottom: 40px;
        }
    }

    &__col {
        transform: translateY(100%);
        transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1),
            transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;

        @include below($s) {
            margin-bottom: 20px;

            &--mmb {
                margin-bottom: 10px;
            }

            &--mnmb {
                margin-bottom: 0;
            }
        }

        &--mt {
            margin-top: 34px;
            @include below($s) {
                margin-top: 0;
            }
        }

        .button {
            width: 100%;
            margin-bottom: 30px;

            @include below($s) {
                padding: 16px 0;
                text-align: center;
                margin-bottom: 10px;
            }

            &--purple {
                padding: 24px;
                @include below($l) {
                    padding: 24px 0;
                }
                @include below($s) {
                    padding: 14px 0;
                }
            }

            &--titled {
                margin-top: 35px;
            }
        }

        &__title {
            color: #fff;
            font-family: $apercumedium;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            margin-bottom: 16px;

            @include below($s) {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    &--animated {
        .bot-view-options__col--first {
            transform: none;
            transition-delay: 0.8s;
            opacity: 1;
        }

        .bot-view-options__col--second {
            transform: none;
            transition-delay: 1.2s;
            opacity: 1;
        }
    }
}

.bot-view-joblisting {
    transform: translateY(100%);
    transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1),
        transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;

    @include below($l) {
        margin-left: 0;
    }

    &--animated {
        transform: none;
        transition-delay: 1.2s;
        opacity: 1;
    }

    &__toolbar {
        span {
            font-size: 16px;
            line-height: 19px;
            font-family: $apercumedium;
            color: $white;
            text-transform: uppercase;
            float: left;

            @include below($s) {
                font-size: 12px;
                line-height: 14px;
            }
        }

        &__format {
            float: right;

            &__button {
                width: 35px;
                height: 34px;
                float: left;
                background-position: center center;
                background-size: 35px 34px;
                background-repeat: no-repeat;
                margin-bottom: 10px;
                opacity: 0.4;
                transition: opacity 0.2s ease-in-out;

                &:hover {
                    opacity: 1;
                }

                &--active {
                    opacity: 1;
                }

                &--full {
                    background-image: url(../img/icon-full-content.svg);
                    margin-right: 5px;
                    @include below($s) {
                        margin-right: 0;
                    }
                }

                &--less {
                    background-image: url(../img/icon-less-content.svg);
                }
            }
        }
    }

    .bot-view-joblisting__item:last-child {
        border-bottom: 2px solid $white;
        margin-bottom: 200px;
        @include below($s) {
            margin-bottom: 100px;
        }
    }

    &__item {
        border-top: 2px solid $white;
        padding: 30px 0 0 0;
        position: relative;

        &__logo {
            position: absolute;
            bottom: 27px;
            right: 0;
            text-align: right;

            &__holder {
                background: #ffffff;
                display: inline-block;
            }

            img {
                padding: 5px;
                max-height: 70px;
                max-width: 100%;
            }
        }

        &__content {
            padding: 0 10px 0 0;
            min-height: 150px;

            p {
                font-size: 20px;
                line-height: 27px;
                color: $white;
                font-family: $freightmedium;
                margin-bottom: 33px;

                @include below($s) {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            h2 {
                a {
                    text-decoration: none;
                    color: #fff;
                    font-size: 26px;
                    line-height: 34px;
                    font-family: $apercumedium;
                    margin-bottom: 19px;

                    @include below($s) {
                        font-size: 22px;
                        line-height: 30px;
                        display: block;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            span {
                font-size: 16px;
                line-height: 19px;
                font-family: $apercumedium;
                color: rgba(255, 255, 255, 0.6);

                @include below($l) {
                    margin-bottom: 30px;
                    display: block;
                }

                @include below($s) {
                    font-size: 12px;
                    line-height: 14px;
                    margin-bottom: 30px;
                    display: block;
                    margin-bottom: 30px;
                }
            }
        }

        &__readmore {
            .button {
                padding: 26px 0px;
                width: 100%;

                @include below($s) {
                    padding: 16px 0;
                }
            }
        }
    }
}

.bot-view-contact {
    transform: translateY(100%);
    transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1),
        transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;

    &--animated {
        transform: none;
        transition-delay: 1.2s;
        opacity: 1;
    }
}
.bot-loader {
    opacity: 0;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #fff;
}

.loading .bot-loader,
.bot-loader.loading {
    opacity: 1;
}
