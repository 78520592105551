.area-select {
    display: flex;
    margin-bottom: 52px;

    @include below($m) {
        width: 100%;
        display: inline-block;
    }

    &__image-wrap {
        position: relative;
        width: 72.63157895%;
        height: 44vw;
        float: left;

        @include below($m) {
            width: 100%;
            height: 58vw;
            float: none;
        }

        &__inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            opacity: 0;
            overflow: hidden;

            img {
                transform: scale(1.1);
                transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
                width: 100%;
                height: auto;
            }

            &--visible {
                opacity: 1;
                img {
                    transform: scale(1);
                }
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-image: linear-gradient(
                    180deg,
                    rgba(32, 42, 73, 0) 0,
                    rgba(32, 42, 73, 0.5) 100%
                );
                z-index: 10;
            }

            &__content {
                position: absolute;
                left: 60px;
                bottom: 60px;
                z-index: 10;

                @include below($m) {
                    left: 20px;
                    bottom: 20px;
                }

                @include below($s) {
                    left: 15px;
                    right: 15px;
                    bottom: 15px;
                }

                h3,
                p {
                    display: inline;
                    font-size: 24px;
                    line-height: 34px;
                    color: #fff;
                    max-width: 520px;
                    width: 100%;
                    letter-spacing: 0.01em;
                    font-family: $apercumedium;

                    @include below($m) {
                        font-size: 18px;
                        line-height: 28px;
                    }

                    @include below($s) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                h3 {
                    font-size: 26px;
                    line-height: 34px;
                    font-family: $platformbold;

                    @include below($m) {
                        font-size: 18px;
                        line-height: 28px;
                    }

                    @include below($s) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    &__area-select {
        float: left;
        width: 27.36842105%;
        display: flex;
        flex-direction: column;
        
        @include below($m) {
            width: 100%;
            flex-direction: row;
        }

        &__area {
            flex: 1;
            position: relative;

            @include below($m) {
                width: 50%;
                float: left;
            }

            @include below(320px) {
                width: 100%;
            }

            a {
                padding: 12.34567901%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-decoration: none;
                height: 100%;
                
                @include below(320px) {
                    padding: 20px;
                }
                &:hover {
                    .arrowed-link {
                        &:after {
                            transform: translateX(10px);
                        }
                    }
                }
            }

            .title {
                @include below($l) {
                    margin-bottom: 30px;
                }

                @include below($m) {
                    margin-bottom: 30px;
                }
                @include below(320px) {
                    br {
                        display: none;
                    }
                }
            }

            .arrowed-link {
                position: absolute;
                bottom: 50px;

                @include below($m) {
                    position: relative;
                    bottom: 0;
                }
            }

            &--purple {
                background: $purple;
            }

            &--blue {
                background: $orange;
            }
        }
    }
}
