.input-wrap {
    margin-bottom: 30px;

    &--submit {
        margin-top: 70px;
        max-width: 300px;
        width: 100%;
    }
}

.label {
    color: $white;
    font-family: $apercumedium;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    display: block;

    @include below($s) {
        font-size: 14px;
        line-height: 14px;
    }
}

.input {
    width: 100%;
    border: none;
    font-size: 24px;
    line-height: 24px;
    color: $darkblue;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px 65px 20px 20px;
    font-family: $apercumedium;
    outline: none;

    &--gray {
        background: #dfe2eb;
    }

    &--date {
        padding: 17px 65px 17px 20px;
    }

    @include below($s) {
        padding: 15px 65px 15px 20px;
        font-size: 14px;
        line-height: 20px;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            font-size: 14px;
            line-height: 20px;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            font-size: 14px;
            line-height: 20px;
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            font-size: 14px;
            line-height: 20px;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            font-size: 14px;
            line-height: 20px;
        }
    }

    &--search {
        background-image: url(../img/icon-search-darkblue.svg);
        background-repeat: no-repeat;
        background-position: center right 24px;
    }

    &.error {
        background-color: #ec5f71;
    }
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + .inputfilelabel {
    font-size: 16px;
    color: $white;
    font-family: $apercubold;
    text-transform: uppercase;
    border: 2px solid $white;
    text-align: center;
    max-width: 190px;
    width: 100%;
    display: inline-block;
    padding: 23px 0;
    transition: background 0.2s ease-in-out;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background: $white;
    color: $purple;
    cursor: pointer;
}

.filename {
    font-size: 16px;
    line-height: 19px;
    color: $white;
    font-family: $apercumedium;
    margin-left: 30px;

    &--file-exists {
        position: relative;
        &:after {
            content: "";
            width: 16px;
            height: 12px;
            background-image: url(../img/icon-check.svg);
            background-size: 16px 12px;
            @include vertical-align(absolute);
            right: -26px;
        }
    }
}

.textarea {
    width: 100%;
    resize: none;
    padding: 20px;
    border: none;
    background-color: rgba(255, 255, 255, 0.7);
    min-height: 140px;
    outline: none;
    font-family: $apercumedium;
    font-size: 24px;
    line-height: 34px;

    &--mb {
        margin-bottom: 10px;
    }

    &.error {
        background-color: red;
    }
}

.disclaimer {
    color: $white;
    font-family: $apercumedium;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
}

.submit {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-family: $apercumedium;
    text-transform: uppercase;
    padding: 26px 0px;
    width: 100%;
    text-align: center;
    transition: background 0.2s ease-in-out;
    background: $pink;
    border: none;

    &:hover {
        background: #c2404f;
    }
}

.select {
    // margin-bottom: 80px;

    @include below($l) {
        margin-bottom: 0;
    }

    &.open {
        &:before {
            transform: rotate(-180deg);
        }
    }

    &:before {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 27px;
        right: 20px;
        transition: transform 0.2s ease-in-out;
        @include below($s) {
            top: 17px;
        }
    }

    &--blue {
        background: $orange;
        color: $darkblue;
        transition: background 0.2s ease-in-out;

        &:before {
            background-image: url(../img/icon-chevron-blue.svg);
            background-size: 12px 12px;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &:hover {
            background: $darkblue;
            color: $white;

            &:before {
                background-image: url(../img/icon-chevron-white.svg);
            }
        }

        .formelements_listwrap {
            color: $darkblue;
        }
    }

    &--purple {
        background: $purple;
        border: 2px solid $white;
        color: $white;
        transition: background 0.2s ease-in-out;

        &:before {
            background-image: url(../img/icon-chevron-white.svg);
            background-size: 12px 12px;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &:hover {
            background: darken($purple, 5%);
        }

        .formelements_listwrap {
            color: $darkblue;
        }
    }

    &--grey {
        background: #dfe2eb;
        max-width: 100% !important;
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        display: none !important;

        @include below($m) {
            display: block !important;
            position: fixed;
            top: 0px;
        }

        @include below($s) {
            position: fixed;
            top: 0px;
        }

        &:before {
            background-image: url(../img/icon-chevron-blue.svg);
            background-size: 12px 12px;
            background-position: center center;
            background-repeat: no-repeat;

            @include below($s) {
                top: 24px;
            }
        }

        .formelements_listwrap {
            color: $darkblue !important;
            background: #c7c9d1 !important;
            padding: 20px !important;
        }

        .formelements_listwrap ul li {
            color: $darkblue !important;
            font-size: 16px;
        }

        .formelements_select_display {
            text-align: left !important;
            padding: 20px !important;
            font-size: 16px !important;
            line-height: 20px !important;
        }
    }
}

.radio-input {
    display: block;
    cursor: pointer;
    display: flex;
    margin-bottom: 0.5rem;
    &__label {
        cursor: pointer;
    }
    &__input {
        visibility: hidden;
        height: 0;
        width: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        left: -999999px;
    }
    &__checkbox {
        display: inline-block;
        vertical-align: middle;
        //padding: 1rem;
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
        background-color: rgba(255, 255, 255, 0.7);
        border: solid 2px $purple;

        color: $white;
        margin-right: 1rem;
        transition: background 0.3s linear;
        position: relative;

        &::after {
            display: block;
            content: "";
            width: 50%;
            height: 50%;
            border-radius: 100%;
            transition: background 0.3s linear;
            @include align-both(absolute);
        }
    }

    &__label-text {
        font-size: 1rem;
        line-height: 1;
        color: $black;
        display: inline-block;
        vertical-align: middle;
    }
    &_input-label--error {
        display: none;
    }
}
.radio-input__input:checked
    + .radio-input__label
    .radio-input__checkbox::after {
    background-color: $purple;
}
// .ApproveTerms__input.error:checked + .ApproveTerms__input-wrap .ApproveTerms__input-label,
// .ApproveTerms__input.error:checked + .ApproveTerms__input-wrap .ApproveTerms__input-label-link {
// 	color: $white;
// }
// .ApproveTerms__input.error:checked + .ApproveTerms__input-wrap .ApproveTerms__input-checkbox {
// 	background-color: rgba(255, 255, 255, 0.7);
// }

.match-better-form {
    width: 100%;
    display: block;
    clear: both;
    overflow: auto;
    .ApproveTerms__input-checkbox {
        border: solid 2px $purple;
    }
    .job-apply__view__content__input-row {
        margin-bottom: 1.5rem;
        overflow: auto;
        clear: both;
    }
    .job-apply__view__content__small-text,
    .label {
        color: $black;
    }
    &__holder {
        display: block;
        max-width: 1290px;
        width: 100%;
        margin: 0 auto;
        padding-top: 2rem;
        min-height: 730px;
        position: relative;
        clear: both;
        overflow: auto;
    }

    .inputfile + .inputfilelabel {
        background-color: $purple;
        border-color: $purple;
        margin-bottom: 1rem;
        &:hover {
            color: $purple;
            background-color: $white;
        }
    }
    .job-apply__view__content__text {
        color: $black;
        margin-bottom: 0.5rem;
    }
}
