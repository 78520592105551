.article-slide {
    position: relative;
    height: 500px;
    width: 100%;
    float: left;

    @include below($m) {
        height: auto;
    }

    &__image {
        width: 90.31746032%;
        position: relative;
        img {
            width: 100%;
            height: auto;
        }

        @include below($m) {
            width: 100%;
        }
    }

    &__noimage {
        width: 90.31746032%;
        height: 190px;
        position: relative;

        @include below($m) {
            width: 100%;
        }
    }

    &__content {
        width: 93.81370826%;
        min-height: 290px;
        padding-top: 7.029876977%;
        padding-left: 8.787346221%;
        padding-right: 8.787346221%;
        padding-bottom: 8.787346221%;
        position: absolute;
        bottom: -60px;
        right: -60px;

        @include below($l2) {
            width: 85%;
            min-height: 230px;
        }

        @include below($l) {
            width: 95%;
            min-height: 230px;
        }

        @include below($m) {
            display: block;
            position: static;
            width: 100%;
            min-height: auto;
        }

        h2 {
            font-size: 40px;
            line-height: 50px;
            color: $darkblue;
            font-family: $platformbold;

            @include below($l2) {
                font-size: 40px;
                line-height: 40px;
            }

            @include below($l) {
                font-size: 35px;
                line-height: 40px;
            }

            @include below($m) {
                margin-bottom: 40px;
                font-size: 20px;
                line-height: 32px;
            }
        }

        .arrowed-link {
            position: absolute;
            bottom: 40px;
            @include below($m) {
                position: relative;
                bottom: auto;
            }
        }

        &--green {
            background: $green;
        }

        &--blue {
            background: #f6ad48;
        }

        &--darkblue {
            background: $darkblue;

            h2 {
                color: $white;
            }
            .arrowed-link {
                color: $white;

                &:after {
                    background-image: url(../img/icon-link-arrow-white.svg);
                }
            }
        }

        &--purple {
            background: $purple;
            h2 {
                color: $white;
            }
            .arrowed-link {
                color: $white;

                &:after {
                    background-image: url(../img/icon-link-arrow-white.svg);
                }
            }
        }
    }
}

.article-slide-container {
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 52px;
    display: inline-block;
}

.swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide {
    width: 850px;
}

.article-slider__pagination {
    position: absolute;
    top: 5px;
    right: 0;
}

.article-slider__counter {
    font-size: 20px;
    line-height: 24px;
    font-family: $apercumedium;
    color: $darkblue;
}

.article-slider__button {
    width: 9px;
    height: 15px;
    display: inline-block;
    background-size: cover;
    background-position: center center;
    &--prev {
        background-image: url("../img/icon-chevron-left.svg");
        margin-right: 13px;
    }
    &--next {
        background-image: url("../img/icon-chevron-right.svg");
        margin-left: 13px;
    }
}
