body {
  padding-top: 95px;

  @include below($m) {
    padding-top: 80px;
  }

  @include below($s) {
    padding-top: 60px;
  }
}

.sitemenu {
  background-color: $darkblue;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  height: 95px;
  transition: transform .2s ease-in-out;

  @include below($m) {
    height: 80px;
  }

  @include below($s) {
    height: 60px;
  }

  &--fixed {
    transform: none !important;
  }

  &--minimized {
    transform: translateY(-95px);

    @include below($m) {
      transform: translateY(-80px);
    }

    @include below($s) {
      transform: translateY(-60px);
    }
  }

  &__logotype {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white;
    padding: 19px 80px;
    text-align: center;

    @include below($m) {}

    @include below($s) {
      padding: 16px 0;
      width: 50%;
    }

    img {
      display: block;
      width: 120px;
      height: auto;

      @include below($m) {
        margin: 0 auto;
        width: 88px;
      }

      @include below($s) {
        margin: 0 auto;
        width: 60px;
      }
    }
  }

  &__plain-listing {
    list-style: none;
    display: inline-block;
    margin-top: 38px;
    margin-bottom: 37px;

    @include below(1080px) {
      display: none;
    }

    // Sry, not pretty
    @include above(1080px) {
      padding-left: 290px;
      padding-right: 150px;
    }

    li:first-child {
      margin-left: 0;
    }

    &__item {
      display: inline-block;
      margin-left: 50px;

      &:hover {
        cursor: pointer;
      }

      &--dark {
        a {
          color: rgba(255, 255, 255, .60);
        }
      }

      &__link {
        font-family: $apercumedium;
        font-size: 16px;
        line-height: 19px;
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        transition: color .2s ease-in-out;
      }
    }
  }

  &__hamburger {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    right: 36px;
    z-index: 13;

    @include below($s) {
      left: 75%;
      right: auto;
      margin-left: -12.5px;
    }

    &:hover {
      cursor: pointer;
    }

    &__text {
      float: left;
      line-height: 14px;
      color: $white;
      text-decoration: none;
      font-family: $apercumedium;
      font-size: 16px;
      line-height: 14px;
      text-transform: uppercase;
      display: block;
      margin-right: 20px;

      @include below($s) {
        display: none;
      }
    }

    #nav-icon1 {
      width: 25px;
      height: 14px;
      position: relative;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      float: left;
      margin-top: -1px;
    }

    #nav-icon1 span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $white;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    #nav-icon1 span:nth-child(1) {
      top: 0px;
    }

    #nav-icon1 span:nth-child(2) {
      top: 7px;
    }

    #nav-icon1 span:nth-child(3) {
      top: 14px;
    }

    #nav-icon1.open span:nth-child(1) {
      top: 7px;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    #nav-icon1.open span:nth-child(2) {
      opacity: 0;
      //left: -60px;
      -webkit-transition: none;
      transition: none;
    }

    #nav-icon1.open span:nth-child(3) {
      top: 7px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }

  }


  &__menu {
    width: 565px;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 80px;
    right: 0;
    background: $darkblue;
    z-index: 16;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    padding: 50px 50px 75px 50px;

    @include below($s) {
      width: 100%;
      top: 60px;
    }

    &--visible {
      -webkit-transform: translateX(0);
      transform: translateX(0);

      .sitemenu__menu__inner {
        opacity: 1;
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
      }

    }

    &__inner {
      opacity: 0;
      width: 100%;
      min-height: 500px;
      height: auto;
      overflow: auto;
      -webkit-transition: opacity .2s, transform .4s cubic-bezier(0.165, 0.84, 0.7, 1);
      transition: opacity .2s, transform .4s cubic-bezier(0.165, 0.84, 0.7, 1);
      -webkit-transition-delay: .2s;
      transition-delay: .2s;
      -webkit-transform: translateX(20%);
      transform: translateX(20%);
    }

    &__navmenu {
      list-style: none;
      margin-bottom: 66px;

      .menu-item {
        display: block;

        a {
            text-decoration: none;
            color: $white;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            font-family: $apercumedium;
            margin-bottom: 24px;
            display: inline-block;
            -webkit-transition: color .2s ease-in-out;
            transition: color .2s ease-in-out;

            @include below($s) {
                font-size: 16px;
                line-height: 22px;
            }
        }

        &.dark a {
            color: rgba(255, 255, 255, .60);
        }

        .sub-menu {
            display: none;
            padding-left: 20px;

        }

    }

    .menu {
        @include below(1080px) {
            > .menu-item.menu-item-has-children {
                position: relative;

                &:after {
                    content: '';
                    width: 0px;
                    height: 0px;
                    border: 8px solid transparent;
                    border-top: 8px solid #fff;
                    position: absolute;
                    pointer-events: none;
                    display: inline-block;
                    top: 6px;
                    margin-left: 20px;
                }

                &:hover {

                    .sub-menu {
                        display: block;
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    }

    &__plain {
      list-style: none;
      margin-bottom: 31px;

      &--social {
        @include below($s) {
          display: none;
        }
      }

      &--contact {
        @include below($s) {
          width: 100%;
          text-align: center;
        }
      }

      a {
        color: $white;
        font-size: 16px;
        line-height: 36px;
        font-family: $apercumedium;
        text-decoration: none;
        -webkit-transition: opacity .2s ease-in-out;
        transition: opacity .2s ease-in-out;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

.menu-site-overlay {
  position: fixed;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(31, 42, 73, .75);
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity .75s;
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: opacity .75s cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &--visible {
    pointer-events: auto;
    opacity: 1;
    -webkit-transition: opacity .3s;
    -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: opacity .3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}

.menu-main-container .menu {
    padding-left: 290px;
    padding-right: 150px;
    list-style: none;
    display: inline-block;
    margin-top: 38px;
    margin-bottom: 37px;
    text-align: center;
    width: 100%;

    > .menu-item {
        display: inline-block;
        margin-left: 50px;
        position: relative;

        &:first-child {
            margin-left: 0;
        }

        a {
            font-family: $apercumedium;
            font-size: 16px;
            line-height: 19px;
            color: #fff;
            text-transform: uppercase;
            text-decoration: none;
            transition: color .2s ease-in-out;
        }

        &.dark a {
            color: rgba(255, 255, 255, .60);
        }

        &.menu-item-has-children > a:after {
            content: '';
            display: none;
            width: 0px;
            height: 0px;
            border: 8px solid transparent;
            border-top: 8px solid $white;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            bottom: -20px;
        }

        > .sub-menu {
            display: none;
            position: absolute;
            width: auto;
            padding: 0;
            padding-top: 38px;
            list-style: none;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 0px 0px 4px 4px;
            box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.05);
            overflow: hidden;

            > .menu-item {
                background-color: #fff;
                padding: 0px;
                min-width: 280px;

                &:first-of-type {
                    padding-top: 25px;
                }

                &:last-of-type {
                    padding-bottom: 25px;
                }

                > a {
                    font-family: $apercubold;
                    padding: 10px 30px;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &.menu-item-type-custom > a {
                    cursor: auto;
                    text-decoration: none !important;
                }

                a {
                    color: #2B2825;
                    display: block;
                    text-align: left;
                    text-transform: none;
                    font-weight: bold;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &.current-menu-item > a {
                    text-decoration: underline;
                }

                > .sub-menu {
                    max-height: 0;
                    overflow: hidden;
                    position: relative;
                    padding: 0 30px 0 50px;
                    margin-top: 7px;

                    .menu-item {
                        display: block;

                        a {
                            font-weight: initial;
                            padding: 7px 0;
                        }
                    }

                }
            }
        }

        &:hover {

            &.menu-item-has-children > a:after {
                display: block;
            }

            > .sub-menu {
                display: block;

                .menu-item-has-children:hover .sub-menu {
                    max-height: 1000px;
                }
            }

        }
    }

    @include below(1400px) {

        > .menu-item > .sub-menu {
            left: initial;
            transform: none;
            right: -50px;
        }
    }

    @include below(1080px) {
        display: none;
    }
}