.step-navigation {
	background:$purple;
	list-style:none;
	display:inline-block;
	width:100%;
	padding:20px 30px;

	li:last-child {
		padding-right:0;
		&:after {
			display:none;
		}
	}

	li:first-child {
		padding-right:10px;
		&:after {
			display:none;
		}
	}

	&__step {
		float:left;
		padding-right:20px;
		position:relative;
		&:after {
			content:'/';
			right:8px;
			@include vertical-align(absolute);
			color:rgba(255, 255, 255, 0.6);
		}

		&__link {
			font-family:$apercumedium;
			color:rgba(255, 255, 255, 0.6);
			text-decoration:none;
			font-size:16px;
			line-height:19px;
			text-transform:uppercase;
			transition:color .2s ease-in-out;

			&:hover {
				color:$white;
			}

			svg {
				display:block;
				width:22px;
				height:15px;
				margin-top:2px;
				g {
					transition:all .2s ease-in-out;
					fill-opacity:0.6;
				}
				&:hover {
					g {
						fill-opacity:1;
						fill:#ffffff;
					}
				}
			}

			&--active {
				color:$white;
			}
		}
	}


}