
.ApproveTerms {

	&__holder {
		display: block;
		clear: both;
    margin-bottom: 2.5rem;
	}

	&__input-wrap {
		display: block;
			cursor: pointer;
      display: flex;
	}

	&__input {
		visibility: hidden;
		height: 0;
		width: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		left: -999999px;
		display: none;
	}
	&__input-checkbox {
		display: inline-block;
		vertical-align: middle;
		//padding: 1rem;
		width: 1rem;
		height: 1rem;
		font-size: 1rem;
		background-color: rgba(255, 255, 255, 0.7);
		color: $white;
		margin-right: 0.5rem;
		transition: background 0.3s linear;
		position: relative;
		&::after {
			display: block;
			content: "";
			width: 50%;
			height: 50%;
			border-radius: 100%;
			transition: background 0.3s linear;
			@include align-both(absolute);
		}
	}

	&__input-label {
    font-size: 1rem;
    line-height: 1;
		color: $white;
	}
	&__input-label-link {
		color: $white;
		font-weight: 700;
		text-decoration: underline;
	}
	&__minicv {
		margin-top: 2rem;
	}

	&_input-label--error {
		display: none;
	}
}

.ApproveTerms__input:checked + .ApproveTerms__input-wrap .ApproveTerms__input-checkbox::after {
	background-color: $purple;

    .flexibleContactForm & {
        background-color: $darkblue;
    }
}

.ApproveTerms__input.error:checked + .ApproveTerms__input-wrap .ApproveTerms__input-label,
.ApproveTerms__input.error:checked + .ApproveTerms__input-wrap .ApproveTerms__input-label-link {
	color: $white;
}
.ApproveTerms__input.error:checked + .ApproveTerms__input-wrap .ApproveTerms__input-checkbox {
	background-color: rgba(255, 255, 255, 0.7);
}

.ApproveTerms__input.error + .ApproveTerms__input-wrap .ApproveTerms__input-checkbox {
	background-color: $pink;
}

.ApproveTerms__input.error + .ApproveTerms__input-wrap .ApproveTerms__input-label,
.ApproveTerms__input.error + .ApproveTerms__input-wrap .ApproveTerms__input-label-link {
	color: $pink;
}

.approve-terms-js-btn:disabled {
	pointer-events: none;
	opacity: 0.5;
}
