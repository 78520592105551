.find-job {
	margin-bottom:52px;

	&__person {
		width:17%;
		float:left;
		margin-bottom:20px;
		margin-right:20px;

		img {
			width:100%;
			height:auto;
		}
	}

	&__content {
		background-color:$purple;
		width: 100%;
		display:inline-block;
		padding:percentage(math.div(50, 1290));

		@include below($s){
			width:100%;

			padding:20px;
		}

		.title {
			margin-bottom:50px;
			@include below($s){
				margin-bottom:40px;
			}
		}

		.button {
			width:100%;
	
			&--purple {
				padding:24px 24px;
			}

			&--pink {
				margin-right:30px;
				padding:26px 0;

				@include below($s){
					padding:16px 0;
					margin-right:0;
					margin-bottom:10px;
				}
			}

			&--white {
				background: $white;
				color: $purple;
				padding: 24px 24px;
				&:hover {
					background: $darkblue;
					color: $white;
				}
			}
		}
	}

	&__tips {
		color:$white;
		font-family:$apercumedium;
		font-size:24px;
		line-height:34px;
		width:100%;
		display:block;
		float:left;

		@include below($l){
			font-size:18px;
			line-height:28px;
		}

		@include below($s){
			font-size:14px;
			line-height:20px;
			padding-right: 30%;
		}

		img {
			width:30px;
			height:auto;
			margin-left:10px;

			@include below($s){
				width:20px;
			}
		}
	}
}