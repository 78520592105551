.teaser {
    margin-bottom: 52px;
    display: inline-block;
    width: 100%;

    @include below($s) {
        margin-bottom: 30px;
    }

    &__image {
        width: 50%;
        float: left;

        @include below($m) {
            width: 90%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content {
        width: 50%;
        float: left;
        position: relative;
        text-align: left;

        @include below($m) {
            width: 100%;
        }

        &__text-ratio {
            position: relative;
            min-height: 400px;

            @include below($m) {
                min-height: auto;
            }

            &__inner {
                position: relative;
                padding: percentage(math.div(50, 645));

                @include below($m) {
                    position: relative;
                    padding: 30px;
                }

                p {
                    font-size: 20px !important;
                    line-height: 32px !important;

                    @include below($l) {
                        font-size: 18px !important;
                        line-height: 28px !important;
                    }

                    @include below($s) {
                        font-size: 14px !important;
                        line-height: 20px !important;
                    }
                }
            }
        }

        .title {
            font-size: 40px !important;
            line-height: 50px !important;
            margin-bottom: 20px;
            word-wrap: break-word;
            max-width: 100%;
            display: block;
            
            @include below($l) {
                margin-bottom: 20px;
            }

            @include below($s) {
                font-size: 24px !important;
                line-height: 30px !important;
            }
        }

        &--darkblue {
            background: $darkblue;
        }

        &--green {
            background: $green;
        }

        &--blue {
            background: $orange;
        }

        &--purple {
            background: $purple;
        }

        .arrowed-link {
            position: relative;
            margin-top: 20px;
            margin-bottom: 40px;
            
            @include below($s) {
                margin-bottom: 20px;
            }
        }
    }
}
