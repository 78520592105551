.flexibleContactFormRow {
    max-width: 768px;
    margin: auto;

    .section-title {
        margin-bottom: 1.25rem;
    }
}
.FlexibleContactForm-form {
    width: 100%;
}
.flexibleContactForm {
    margin-bottom: 2rem;
    background-color: $darkblue;
    color: white;

    &-holder {
        padding: 1rem;
        @include above($s) {
            padding: 3rem;
        }
    }
    &-row {
        @include above($s) {
            margin-bottom: 1rem;
        }
    }

    &-header {
        padding-bottom: 1.5rem;
    }
    &-preamble {
        font-size: 1.25rem;
        max-width: 600px;
    }

    .ApproveTerms {

        @include above($s) {
            margin-top: 1rem;
        }
    }

    .required-explainer-text {
        margin-bottom: 1rem;
        display: block;
    }

    .SubmitButton {
        max-width: 300px;
        width: 100%;
        min-width: 120px;
        padding-left: 2rem;
        padding-right: 2rem;

        @include above($m) {
            float: right;
            border: none;
        }
    }
}